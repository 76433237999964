import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./_i18n";
import Init from "./Init";
// import { register } from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Fragment>
    <Init />
  </React.Fragment>
);

// register service worker
// register();

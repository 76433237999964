import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as enCommon from "./locales/common/en.json";
import * as ptCommon from "./locales/common/pt.json";
import * as enValves from "./locales/valves/en.json";
import * as ptValves from "./locales/valves/pt.json";
import * as enWaterQuality from "./locales/waterquality/en.json";
import * as ptWaterQuality from "./locales/waterquality/pt.json";
import * as enWaterMeter from "./locales/watermeter/en.json";
import * as ptWaterMeter from "./locales/watermeter/pt.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      "en-US": {
        common: (enCommon as any).default,
        valves: (enValves as any).default,
        waterquality: (enWaterQuality as any).default,
        watermeter: (enWaterMeter as any).default,
      },
      "pt-PT": {
        common: (ptCommon as any).default,
        valves: (ptValves as any).default,
        waterquality: (ptWaterQuality as any).default,
        watermeter: (ptWaterMeter as any).default,
      },
    },
    fallbackLng: "en-US",
    detection: {
      order: ["navigator"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    ns: ["common", "valves"],
    defaultNS: "common",
  })
  .catch((err) => {
    console.error("failed to load i18n:", err);
  });

export default i18n;

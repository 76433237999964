import React from "react";
import Spinner from "./Spinner";

const Loading = (): JSX.Element => (
  <div className="flex justify-center items-center" style={{ height: "100vh" }}>
    <Spinner width="25%" />
  </div>
);

export default Loading;

import React, { lazy } from "react";
import Loading from "./Loading";
import { BrowserRouter } from "react-router-dom";

const Login = lazy(async () => await import("./Login"));

const Init = (): JSX.Element => {
  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Login />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Init;

import React, { memo } from "react";

interface Worm {
  center: number;
  span: number;
}

const doFill = (i: number, worm: Worm): string => {
  const { center, span } = worm;

  const dist = Math.abs(center - i);
  const realDist = dist < 45 / 2 ? dist : Math.abs(dist - 45);

  if (realDist <= span) {
    return "#2ABEBE";
  }
  return "none";
};

const getCachedWorm = (): Worm => {
  const cachedWorm = sessionStorage.getItem("ui:worm");
  if (cachedWorm != null) {
    try {
      return JSON.parse(cachedWorm);
    } catch (e) {}
  }
  return { center: 1, span: 0 };
};

const setCachedWorm = (worm: Worm): void => {
  sessionStorage.setItem("ui:worm", JSON.stringify(worm));
};

const SvgComponent = memo(function SvgComponent(props: any): JSX.Element {
  const [worm, setWorm] = React.useState<Worm>(getCachedWorm());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setWorm((worm) => ({
        span: Math.min(7, worm.span + 1),
        center: (worm.center + 1) % 45,
      }));
    }, 50);
    return () => {
      setCachedWorm(worm);
      clearInterval(interval);
    };
  }, [worm]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 250 908 450" {...props}>
      <path
        d="M406.783 385.063c-13.407 6.53-25.182-6.608-23.879-17.15.855-6.913 7.224-14.168 14.149-14.906 9.023-.961 17.124 4.15 18.948 11.957 2.143 9.167-.631 15.444-9.218 20.1z"
        fill={doFill(1, worm)}
      />
      <path
        d="M352.911 353.373c-10.663-8.4-8.406-19.544-3.48-25.267 5.724-6.651 14.81-7.56 22.191-3.09 8.844 5.357 8.62 15.951 5.995 21.656-3.604 7.832-12.344 11.123-20.774 8.356-1.239-.406-2.442-.921-3.932-1.655z"
        fill={doFill(2, worm)}
      />
      <path
        d="M310.989 318.767c-2.633-8.215.892-16.193 8.612-19.937 7.203-3.492 17.503-1.177 21.256 4.779 4.998 7.934 3.72 17.024-2.386 22.699-9.4 8.735-23.914 4.095-27.482-7.541z"
        fill={doFill(3, worm)}
      />
      <path
        d="M270.557 306.056c-2.318-12.765 1.191-19.473 10.845-23.14 6.295-2.391 16.602 1.366 19.775 8.416 3.52 7.82 1.715 17.062-4.85 21.568-9.023 6.192-19.218 3.578-25.77-6.844z"
        fill={doFill(4, worm)}
      />
      <path
        d="M235.908 280.581c12.246-2.29 18.095-.156 21.887 7.99 4.116 8.84.485 18.561-7.427 22.575-8.37 4.245-19.277.782-23.034-7.593-3.38-7.537-1.457-19.258 8.574-22.972z"
        fill={doFill(5, worm)}
      />
      <path
        d="M190.797 313.376c-8.823-3.916-9.39-17.445-5.68-23.072 5.468-8.286 15.12-10.54 23.963-5.284 6.54 3.888 9.885 15.796 5.565 22.493-4.692 7.272-13.725 11.845-23.848 5.863z"
        fill={doFill(6, worm)}
      />
      <path
        d="M152.577 298.197c11.477-4.245 22.366 3.62 23.246 14.461.53 6.53-2.6 12.41-8.202 15.92-5.438 3.409-11.604 3.193-17.276.073-6.45-3.546-8.696-9.533-8.302-16.546.376-6.694 4.114-11.137 10.534-13.908z"
        fill={doFill(7, worm)}
      />
      <path
        d="M122.201 322.007c7.24.661 13.654 2.557 16.457 9.447 3.6 8.844 1.566 18.283-7.522 22.845-8.578 4.305-19.22.643-22.718-7.406-4.123-9.488-.333-19.774 8.698-23.458 1.5-.612 3.108-.956 5.085-1.428z"
        fill={doFill(8, worm)}
      />
      <path
        d="M110.807 381.473c-7.774 8.115-15.4 9.934-23.07 5.807-7.621-4.1-10.575-13.663-6.805-22.034 3.649-8.101 12.905-11.536 22.088-8.197 7.375 2.683 11.839 12.076 9.453 19.962-.422 1.395-.998 2.745-1.666 4.462z"
        fill={doFill(9, worm)}
      />
      <path
        d="M63.013 406.373c4.117-8.207 10.746-12.363 17.485-11.343 8.24 1.247 14.08 7.386 15.455 16.244.873 5.622-4.365 13.993-10.283 16.434-7.316 3.02-16.254.806-20.41-5.13-3.405-4.861-4.433-10.111-2.247-16.205z"
        fill={doFill(10, worm)}
      />
      <path
        d="M74.618 438.293c11.204 1.344 14.275 9.445 15.13 16.445.891 7.306-4.553 14.492-11.662 16.686-8.143 2.513-17.896-1.62-20.829-8.828-3.608-8.866-.286-18.721 8.018-22.564 2.71-1.254 5.968-1.325 9.343-1.739z"
        fill={doFill(11, worm)}
      />
      <path
        d="M65.243 487.429c9.091-9.194 20.135-8.816 27.531.964 4.115 5.442 4.063 12.38-.062 19.169-3.22 5.3-11.1 7.64-17.14 6.807-11.971-1.653-17.769-16.816-10.329-26.94z"
        fill={doFill(12, worm)}
      />
      <path
        d="M79.655 534.738c1.018-7.81 6.362-11.606 12.941-13.492 5.815-1.667 13.734 1.345 17.163 6.185 4.068 5.742 4.32 13.64.842 19.468-3.763 6.309-12.913 9.632-19.593 7.094-8.028-3.05-12.668-10.384-11.353-19.255z"
        fill={doFill(13, worm)}
      />
      <path
        d="M136.285 559.81c6.973 11.652 4.15 23-6.893 27.28-5.78 2.241-11.548 1.537-16.61-2.75-5.92-5.015-7.072-11.374-5.169-18.352 1.672-6.13 5.804-9.997 12.335-11.014 6.09-.948 11.602-.081 16.337 4.835z"
        fill={doFill(14, worm)}
      />
      <path
        d="M154.624 580.022c8.742-.764 15.677.909 19.574 9.462 3.792 8.32-.168 17.027-5.008 20.777-5.268 4.082-15.732 3.857-20.795-.38-6.578-5.507-8.822-13.902-4.358-21.129 2.234-3.616 6.7-5.852 10.587-8.73z"
        fill={doFill(15, worm)}
      />
      <path
        d="M186.703 622.178c-7.966-11.872-2.637-24.484 8.348-27.72 8.29-2.441 18.683 3.201 20.82 11.344 2.576 9.814-2.627 18.847-11.858 21.26-6.71 1.753-12.162-.124-17.31-4.884z"
        fill={doFill(16, worm)}
      />
      <path
        d="M232.907 628.37c-8.469-5.967-8.504-18.222-3.93-24.506 4.905-6.735 11.891-6.388 18.774-5.716 5.31.518 10.318 6.646 11.27 12.32 1.05 6.256.32 12.145-4.723 16.377-6.45 5.415-13.71 5.523-21.39 1.524z"
        fill={doFill(17, worm)}
      />
      <path
        d="M294.034 595.588c7.348 4.902 10.402 11.558 8.928 19.117-1.242 6.372-7.824 12.06-15.339 13.256-5.33.847-13.118-3.422-15.957-8.747-3.86-7.243-3.262-13.483 1.192-18.639 5.824-6.741 12.782-8.066 21.176-4.987z"
        fill={doFill(18, worm)}
      />
      <path
        d="M310.597 592.87c2.978-10.084 8.195-13.472 19.569-12.869 6.096.323 13.081 6.828 13.725 13.524.789 8.21-1.768 15.569-10.523 18.57-12.845 4.403-24.588-3.768-22.771-19.225z"
        fill={doFill(19, worm)}
      />
      <path
        d="M378.97 574.483c-2.978 13.616-18.393 17.613-28.032 9.488-4.585-3.865-6.806-9.927-4.777-18.187 1.356-5.521 7.254-10.2 12.796-10.81 10.299-1.132 15.18 1.742 19.017 10.385 1.131 2.548.722 5.78.997 9.124z"
        fill={doFill(20, worm)}
      />
      <path
        d="M377.58 526.973c5.613-6.936 12.103-9.973 20.676-7.017 7.517 2.592 11.34 8.996 10.742 18.382-.409 6.417-6.378 12.3-13.212 13.727-13.305 2.776-23.385-8.646-19.327-22.02.283-.933.568-1.865 1.122-3.072z"
        fill={doFill(21, worm)}
      />
      <path
        d="M428.086 513.422c-12.03 2.246-18.907-.512-22.224-9.079-2.948-7.614-1.352-15.088 6.032-20.681 5.096-3.86 15.041-3.53 19.834.569 6.388 5.464 8.566 13.901 4.56 20.78-1.848 3.176-5.216 5.466-8.202 8.411z"
        fill={doFill(22, worm)}
      />
      <path
        d="M456.38 439.179c10.474 5.889 13.543 16.22 7.657 25.54-3.736 5.914-9.518 7.976-16.146 7.2-6.214-.727-11.036-3.848-13.456-9.989-2.33-5.912-2.201-11.607 1.876-16.687 5.123-6.383 11.82-8.29 20.069-6.064z"
        fill={doFill(23, worm)}
      />
      <path
        d="M467.165 412.694c2.94-8.06 8.94-12.53 16.61-12.692 6.112-.13 13.14 4.27 15.278 10.505 2.108 6.143 1.638 13.683-3.314 18.35-5.22 4.919-12.117 6.684-18.538 4.114-6.295-2.519-10.164-8.346-10.198-15.38-.007-1.497.03-2.993.162-4.897z"
        fill={doFill(24, worm)}
      />
      <path
        d="M501.354 359.289c9.726-6.25 16.429-5.548 23.783 1.192 5.809 5.324 5.832 15.588 2.09 20.69-5.869 8-15.367 10.148-23.01 5.467-7.296-4.468-12.64-15.923-2.863-27.35z"
        fill={doFill(25, worm)}
      />
      <path
        d="M545.154 322.91c8.198-2.47 14.678.717 19.108 7.087 3.512 5.05 4.331 12.371-1.257 20.021-3.548 4.857-11.272 7.136-17.588 5.116-8.247-2.638-12.227-7.615-12.417-15.528-.187-7.782 3.909-13.578 12.154-16.696z"
        fill={doFill(26, worm)}
      />
      <path
        d="M584.19 330.993c-8.402-1.707-14.271-5.737-15.85-14.302-1.097-5.944.756-11.406 5.028-15.182 4.796-4.239 10.707-5.823 17.393-3.665 12.5 4.034 15.882 22.194 3.506 30.164-2.73 1.757-6.413 2.032-10.076 2.985z"
        fill={doFill(27, worm)}
      />
      <path
        d="M610.026 305.553c-2.443-9.671.86-17.68 8.647-21.428 8.18-3.937 19.734.019 23.008 8.325 3.941 9.996-1.24 20.805-9.874 22.891-8.123 1.963-15.124 2.465-21.78-9.788z"
        fill={doFill(28, worm)}
      />
      <path
        d="M682.302 307.2c-6.605 8.562-23.73 8.316-28.417-4.777-2.022-5.651-1.46-13.54 3.048-17.964 4.863-4.772 12.069-6.94 18.838-4.137 6.807 2.819 10.038 7.778 10.287 15.196.145 4.3-.576 8.145-3.756 11.682z"
        fill={doFill(29, worm)}
      />
      <path
        d="M723.315 286.692c9.87 6.92 7.033 25.382-5.442 28.917-8.69 2.461-18.255-1.9-20.955-9.68-3.366-9.698.502-19.043 10.021-22.16 5.426-1.777 11.363-2.03 16.376 2.923z"
        fill={doFill(30, worm)}
      />
      <path
        d="M742.308 326.8c-7.191-4.947-6.444-18.884-2.099-23.618 6.486-7.066 15.694-8.407 23.407-2.914 5.967 4.249 8.466 14.761 5.09 21.414-3.588 7.072-12.558 10.866-19.969 8.3-2.134-.74-4.108-1.94-6.43-3.183z"
        fill={doFill(31, worm)}
      />
      <path
        d="M773.007 332.423c6.913-13.448 20.842-12.049 27.647-5.272 5.062 5.04 6.138 13.992 2.43 20.547-4.105 7.26-11.212 9.8-20.408 7.29-6.218-1.697-10.75-8.127-10.631-15.402.036-2.258.601-4.507.962-7.163z"
        fill={doFill(32, worm)}
      />
      <path
        d="M829.77 382.547c-8.013 11.277-27.387 7.4-30.18-5.292-1.396-6.348.907-14.535 6.246-18.553 4.653-3.503 14.683-3.744 19.703-.474 5.437 3.542 8.457 11.643 6.644 18.18-.551 1.99-1.477 3.874-2.413 6.139z"
        fill={doFill(33, worm)}
      />
      <path
        d="M834.793 396c9.783.661 15.484 7.815 15.207 18.533-.197 7.615-7.674 14.444-15.838 14.467-8.902.025-16.041-4.802-17.138-11.586-1.733-10.713 2.188-18.31 10.973-20.83 2.01-.577 4.232-.41 6.796-.584z"
        fill={doFill(34, worm)}
      />
      <path
        d="M842.24 438.644c9.972 1.655 14.914 9.021 13.755 20.217-.78 7.536-9.247 13.644-18.213 13.138-8.3-.469-14.03-7.583-14.967-16.054-1.192-10.768 9.213-19.555 19.425-17.3z"
        fill={doFill(35, worm)}
      />
      <path
        d="M821.754 510.258c-5.082-5.517-6.46-13.989-3.466-19.955 2.708-5.398 9.992-10 15.71-9.212 7.411 1.021 13.116 4.023 15.586 12.06 2.156 7.013-1.115 15.875-7.066 19.633-6.036 3.812-14.931 2.832-20.764-2.526z"
        fill={doFill(36, worm)}
      />
      <path
        d="M812.915 554.063c-7.524-.343-13.157-8.367-13.896-13.79-.91-6.678 2.892-13.966 8.39-17.085 7.272-4.124 15.876-2.48 20.473 2.294 4.342 4.508 6.29 10.14 4.679 16.317-1.632 6.256-5.659 10.683-12.19 12.114-2.256.495-4.694.154-7.456.15z"
        fill={doFill(37, worm)}
      />
      <path
        d="M795.79 555.94c7.991 4.332 10.86 10.724 9.176 19.707-.955 5.1-7.911 11.74-12.93 12.345-8.981 1.082-16.138-2.59-19.128-10.556-2.322-6.186-1.651-12.59 2.91-17.511 4.084-4.406 9.455-6.832 15.84-5.041 1.243.349 2.503.641 4.132 1.057z"
        fill={doFill(38, worm)}
      />
      <path
        d="M750.415 612.996c-7.145-1.314-12.104-5.172-13.222-11.835-1.255-7.484-.55-14.927 7.414-19.261 5.491-2.99 11.12-3.002 16.47-.215 5.347 2.787 8.944 7.092 8.945 13.666.002 11.9-6.184 17.652-19.607 17.645z"
        fill={doFill(39, worm)}
      />
      <path
        d="M697.6 602.943c4.505-8.747 16.487-11.064 24.524-5.873 7.066 4.564 9.164 13.936 5.297 21.926-4.103 8.479-13.989 11.443-22.728 7.239-7.947-3.824-11.182-13.927-7.093-23.292z"
        fill={doFill(40, worm)}
      />
      <path
        d="M673.753 598.005c12.931 3.715 14.19 15.866 11.11 23.112-3.944 9.278-15.055 12.79-23.578 7.963-7.467-4.23-10.4-12.81-7.305-21.362 2.738-7.565 9.557-11.173 18.368-9.729.325.053.665.01 1.405.016z"
        fill={doFill(41, worm)}
      />
      <path
        d="M643 610.151c-.238 8.64-3.797 14.635-11.864 17.152-6.395 1.994-12.555.938-17.204-4.02-4.785-5.1-6.315-11.334-3.893-18.194 2.056-5.82 10.855-11.958 16.583-10.975 8.317 1.428 15.187 5.74 16.377 16.037z"
        fill={doFill(42, worm)}
      />
      <path
        d="M601.442 591.933c1.206 10.304-1.467 16.29-8.82 19.77-6.95 3.292-19.165 1.518-23.126-8.617-3.462-8.856.876-19.528 9.649-22.678 9.147-3.284 19.457 1.955 22.297 11.525z"
        fill={doFill(43, worm)}
      />
      <path
        d="M544.224 587.112c-6.87-2.697-10.591-7.358-11.235-14.408-.682-7.466 2.906-13.035 8.857-16.45 6.087-3.492 13.635-2.929 19.793 2.935 5.73 5.457 6.927 13.758 3.183 20.247-4.18 7.247-11.694 10.133-20.598 7.676z"
        fill={doFill(44, worm)}
      />
      <path
        d="M516.167 519.992c6.763-2.844 18.278 1.88 20.286 11.01 1.4 6.366 1.042 12.319-4.164 17.31-4.778 4.58-10.247 5.72-16.333 4.09-6.903-1.85-11.807-7.802-11.956-13.915-.257-10.593 2.571-15.039 12.167-18.495z"
        fill={doFill(45, worm)}
      />
    </svg>
  );
});

export default SvgComponent;
